import { IRootState } from 'app/config/store';

export const getCurrentUser = (state: IRootState) => {
  return state.authentication.currentUser;
};

export const hasPermission = (state: IRootState, permission: string) => {
  const currentUser = state.authentication.currentUser;
  // check if the current user has the permission in parameter
  return currentUser && currentUser.user && currentUser.user.permissions.includes(permission);
};

export const hasAnyPermission = (permissions: string[], hasAnyPermissions: string[]) => {
  if (permissions && permissions.length !== 0) {
    if (hasAnyPermissions.length === 0) {
      return true;
    }
    return hasAnyPermissions.some(permission => permissions.includes(permission));
  }
  return false;
};
