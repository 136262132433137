import { IHeaderProps } from 'app/shared/layout/header/header';
import { NavbarBrand } from 'app/shared/layout/header/header-components';
import { AccountMenu, AdminMenu, LocaleMenu } from 'app/shared/layout/menus';
import React from 'react';
import './navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getCurrentUser } from 'app/shared/reducers/authentication.selector';
import { Button } from 'app/shared/components/button';
import { setActiveView } from 'app/modules/planning/planning.reducer';
import Tooltip from 'app/shared/components/tooltip/Tooltip';
import { useLocation, useNavigate } from 'react-router';
import { initializeSelectCalendarDate } from 'app/modules/calendar/calendar.reducer';

const Navbar = ({ props, handleLocaleChange }: { props: IHeaderProps; handleLocaleChange }) => {
  const currentUser = useAppSelector(getCurrentUser);
  const activeView = useAppSelector(state => state.planning.activeView);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <nav className="shadow-md tw-navbar fixed top-0 w-full z-50">
      <div className="relative flex items-center">
        {/* <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <button
            type="button"
            className="relative hidden inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span className="absolute -inset-0.5"></span>
            <span className="sr-only">Open main menu</span>
            <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
            <svg className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div> */}
        <NavbarBrand />
        <div className="w-full flex items-center justify-end pr-2 py-2 md:pr-4 bg-gradient-to-r from-slate-50 to-blue-950 ">
          {/* Switch calendar and plannng view */}
          <Button
            variant={activeView === 'planning' ? 'default' : 'secondary'}
            size="sm"
            onClick={() => {
              dispatch(setActiveView('planning'));
              dispatch(initializeSelectCalendarDate({}));
              if (!location.pathname.includes('planning')) {
                // Redirect to /planning and dispatch the action after the redirect
                navigate('/planning', { replace: true });
              }
            }}
            className={`
          items-center space-x-2 px-4 py-2
          rounded-l-md rounded-r-none px-2
          border-[1px] border-white hidden sm:flex
          ${activeView === 'planning' ? 'z-10 bg-white fill-gray-500' : 'bg-transparent fill-white'}
        `}
          >
            <Tooltip text={'Planning View'} position="bottom">
              <div className="size-5">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 417.64 379.46">
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path d="M208.87,379.35c-51.17,0-102.35.31-153.52-.11C23.77,379,.25,355,.18,323.32,0,234.62.28,145.92,0,57.22-.09,26.78,24-.11,56.83,0c95,.31,190,.07,285.05.06,7.67,0,15.34-.08,23,.19,28.09,1,51.25,23.8,52.25,52,.62,17.32.46,34.67.47,52q.06,106.55,0,213.08c0,14.33-2.06,27.95-11,39.69-11.48,15.08-26.8,22.44-45.74,22.43q-76,0-152,0Zm0-258.35q91.52,0,183,0c10.84,0,12.57-1.63,12.63-12.35.08-16,.24-32-.09-48A67.39,67.39,0,0,0,402,42.43c-5.35-17.38-21.86-29.35-44.58-29.24-88.84.4-177.69.16-266.53.16-11.83,0-23.67-.16-35.5.13-23.76.59-41.24,18-41.66,41.64-.33,18.84-.17,37.68-.12,56.52,0,7.21,2,9,9.27,9.33,2,.07,4,0,6,0ZM140,250q0,52.26,0,104.53c0,10.49,1,11.68,11.24,11.69q57.75,0,115.51,0c9.84,0,11.14-1.4,11.14-11.38q0-85.28,0-170.55c0-13.68.07-27.35-.07-41-.07-7.38-1.92-9.17-9.31-9.18q-59.51,0-119,.05c-8,0-9.46,1.42-9.48,9.33Q140,196.75,140,250ZM291,250h0q0,53.23,0,106.48c0,7.85,1.92,9.76,9.82,9.78,20.33,0,40.66.18,61-.09,23.93-.31,42.12-17.67,42.33-41.54.48-56.16.28-112.31.36-168.47,0-3.66.14-7.33,0-11-.39-9.49-2-11-11.3-11q-45.49,0-91-.05c-9.54,0-11.21,1.49-11.23,10.92Q290.89,197.47,291,250Zm-164.26-.21q0-52.75,0-105.49c0-7.72-2.06-10.09-9.08-10.11q-47.73-.14-95.46,0c-6.17,0-8.48,2.38-8.48,8.58,0,60.65-.21,121.31.16,182,.14,23.69,18.12,41,41.77,41.46,13.49.24,27,.1,40.49.1,7.49,0,15,.08,22.49-.11,5.87-.15,7.63-2,8.05-7.9.11-1.49.09-3,.09-4.49Q126.71,301.75,126.7,249.76Z" />
                    </g>
                  </g>
                </svg>
              </div>
            </Tooltip>
          </Button>
          <Button
            variant={activeView === 'calendar' ? 'default' : 'secondary'}
            size="sm"
            onClick={() => {
              if (!location.pathname.includes('planning')) {
                // Redirect to /planning and dispatch the action after the redirect
                navigate('/planning', { replace: true });
              }
              dispatch(setActiveView('calendar'));
              dispatch(initializeSelectCalendarDate({}));
            }}
            className={`
          sm:flex items-center space-x-2 px-2 py-2 hidden
          rounded-r-md rounded-l-none border-[1px] border-white
          ${activeView === 'calendar' ? 'z-10 bg-white fill-gray-500' : 'bg-transparent fill-white'}
        `}
          >
            <Tooltip text={'Calendar View'} position="bottom">
              <div className="size-5">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.28 127.54">
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path d="M0,116.42V23.79a10.74,10.74,0,0,0,.58-1.3c2.18-6.75,6.7-10.13,13.81-10.28a29.87,29.87,0,0,0,4-.09c.66-.11,1.7-.78,1.74-1.27C20.71,5.06,24.32,2,29.33,0h4.55C39,2,42.66,5.07,43.16,11c0,.45,1.17,1.16,1.79,1.16q11.1.12,22.19,0c.67,0,1.86-.79,1.92-1.33C69.81,4.84,73.45,1.62,78.88,0h4c3.47,1.55,6.79,3.24,8.16,7.2.57,1.68.87,4,2.08,4.75s3.59.23,5.44.25h1.27c8.47.15,14.31,5.78,14.34,14.3q.14,43.62,0,87.24a13.4,13.4,0,0,1-13.55,13.74q-43.58.13-87.15,0a13,13,0,0,1-11.1-6A35.54,35.54,0,0,1,0,116.42ZM105.21,49.11c-1.47-.11-2.7-.3-3.93-.3q-22.11,0-44.21,0c-14.91,0-29.82,0-44.73,0C10,48.81,9,49.25,9,52c.14,19.9.07,39.8.08,59.69,0,5.5,1.31,6.82,6.84,6.82q41.45,0,82.88,0c5,0,6.43-1.48,6.43-6.48q0-29.83,0-59.69ZM57.14,40h44.72c3.35,0,3.36,0,3.36-3.24,0-2-.1-4,0-6.06.4-8.19-1.18-9.27-9-9-4.81.19-3.82-.55-4.71,3.86A11.05,11.05,0,0,1,78.45,34c-5.28-1.24-8.48-4.7-9.13-10-.16-1.31-.27-2.26-1.95-2.25-7.5,0-15,0-22.48.08-.65,0-1.43,1-1.87,1.69a7,7,0,0,0-.51,2.18,11.28,11.28,0,0,1-10.39,8.72A11.44,11.44,0,0,1,21,26.62c-.51-1.61-.64-3.88-1.76-4.61s-3.41-.27-5.18-.24c-3.17,0-4.9,1.83-4.93,5.07,0,3.46.11,6.92-.07,10.37-.12,2.28.7,2.84,2.89,2.83C27,40,42.06,40,57.14,40ZM27.8,17.17c0,2-.06,4,0,6.07a3.6,3.6,0,0,0,3.72,3.59c2.2,0,3.82-1.26,3.87-3.4q.17-6.08,0-12.14a3.69,3.69,0,0,0-3.94-3.7,3.87,3.87,0,0,0-3.67,4C27.76,13.45,27.8,15.31,27.8,17.17Zm56.67.15h0c0-1.94,0-3.87,0-5.8a3.82,3.82,0,1,0-7.63-.12c-.1,3.86-.11,7.73,0,11.6a3.67,3.67,0,0,0,4,3.83c2.15-.08,3.57-1.59,3.62-4C84.5,21,84.47,19.17,84.47,17.32Z" />
                      <path d="M73.37,102.28c0,3.53-2.37,6-5.69,6a5.64,5.64,0,0,1-5.63-5.85,5.84,5.84,0,0,1,5.58-6.21C70.88,96.18,73.36,98.79,73.37,102.28Z" />
                      <path d="M52.14,102.4c0,3.41-2.31,5.87-5.53,5.9a5.78,5.78,0,0,1-5.86-5.94,5.92,5.92,0,0,1,5.77-6.11C49.77,96.25,52.16,98.86,52.14,102.4Z" />
                      <path d="M94.54,102.27c0,3.41-2.27,6-5.46,6a5.72,5.72,0,0,1-5.78-6,5.88,5.88,0,0,1,5.47-6.11A5.75,5.75,0,0,1,94.54,102.27Z" />
                      <path d="M88.76,70.41a5.44,5.44,0,0,1-5.66-5.56c.2-3.08,2-6.4,5.85-6.28a5.91,5.91,0,0,1,5.61,6.23A5.45,5.45,0,0,1,88.76,70.41Z" />
                      <path d="M67.52,70.41a5.32,5.32,0,0,1-5.47-5.63c.15-3.48,2.17-6.4,5.79-6.21,3.22.17,5.56,2.75,5.53,6.29A5.49,5.49,0,0,1,67.52,70.41Z" />
                      <path d="M30.89,102.32a5.72,5.72,0,0,1-5.49,6,5.82,5.82,0,0,1-5.62-6.16,6,6,0,0,1,5.45-5.88C28.38,96.15,30.88,98.83,30.89,102.32Z" />
                      <path d="M46.4,70.41a5.44,5.44,0,0,1-5.53-5.61c.05-2.8,2.24-6.41,5.85-6.31s5.51,3.42,5.45,6.61S49.73,70.46,46.4,70.41Z" />
                      <path d="M25.35,89.09a5.44,5.44,0,0,1-5.62-5.77,5.87,5.87,0,0,1,5.59-5.72,5.92,5.92,0,0,1,5.82,5.79A5.69,5.69,0,0,1,25.35,89.09Z" />
                      <path d="M88.73,89.09a5.72,5.72,0,0,1-5.66-5.6,6.21,6.21,0,0,1,5.79-5.9,5.88,5.88,0,0,1,5.7,5.89A5.52,5.52,0,0,1,88.73,89.09Z" />
                      <path d="M52.14,83.55a5.36,5.36,0,0,1-5.79,5.54,5.59,5.59,0,0,1-5.5-5.66,5.73,5.73,0,0,1,5.75-5.76A5.58,5.58,0,0,1,52.14,83.55Z" />
                      <path d="M73.38,83.42a5.51,5.51,0,0,1-5.72,5.67,5.39,5.39,0,0,1-5.58-5.53,5.84,5.84,0,0,1,5.55-6A6,6,0,0,1,73.38,83.42Z" />
                    </g>
                  </g>
                </svg>
              </div>
            </Tooltip>
          </Button>
          {/* Notifications */}
          <button type="button" className="relative nav-link-item p-1 ml-1 mr-2">
            <span className="absolute -inset-1.5"></span>
            <span className="sr-only">View notifications</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
              />
            </svg>
          </button>
          <div className="nav-link-item hidden sm:block cursor-pointer">
            <FontAwesomeIcon className="text-lg" icon={faPrint} />
          </div>
          {props.isAuthenticated && props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled} />}
          <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />
          <div className="mr-2"></div>
          <AccountMenu isAuthenticated={props.isAuthenticated} currentUser={currentUser} />
        </div>
      </div>

      {/* Mobile  */}
      {/* <div className="sm:hidden" id="mobile-menu">
        <div className="space-y-1 px-2 pb-3 pt-2">
          <a href="#" className="bg-gray-900 text-white block rounded-md px-3 py-2 text-base font-medium" aria-current="page">
            Dashboard
          </a>
          <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">
            Team
          </a>
          <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">
            Projects
          </a>
          <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">
            Calendar
          </a>
        </div>
      </div> */}
    </nav>
  );
};

export default Navbar;
