export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
};

export const PERMISSIONS = {
  CREATE_SHIFT: 'Create Shift',
  DELETE_SHIFT: 'Delete Shift',
  EDIT_SHIFT: 'Edit Shift',
  VIEW_SHIFT: 'View Shift',
  CREATE_RESOURCE: 'Create Resource',
  DELETE_RESOURCE: 'Delete Resource',
  EDIT_RESOURCE: 'Edit Resource',
  VIEW_RESOURCE: 'View Resource',
  CREATE_REF_DEPARTMENT: 'Create Ref Department',
  VIEW_REF_DEPARTMENT: 'View Ref Department',
  ACTIVITY_ADD: 'activity_add',
  RESOURCE_ADD: 'resource_add',
  TEAM_ADD: 'team_add',
  DEMAND_EDIT: 'demand_edit',
  PLAN_EDIT: 'plan_edit',
  SHIFT_PRINT: 'shift_print',
  SHIFT_HIDE: 'shift_hide',
  AUTO_PLAN: 'auto_plan',
  PLAN_APPROVE: 'plan_approve',
  PLAN_SUBMIT: 'plan_submit',
  PLAN_VIEW: 'plan_view',
  RESOURCE_PLAN_ADD: 'resource_plan_add',
  RESOURCE_PLAN_DELETE: 'resource_plan_delete',
  VIEW_MOBILE: 'view_mobile',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';

export const API_BASE_URL = '/api';
