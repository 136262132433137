import React from 'react';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink as Link } from 'react-router-dom';
import './header-components.css';

export const BrandIcon = props => <img className="h-12 w-[180px]" src="content/images/zippy_by_marsatek_logo.png" alt="Logo" />;

export const NavbarBrand = () => (
  <Link to="/planning">
    <BrandIcon />
    {/* <span className="brand-title">
      <Translate contentKey="global.title">Roster</Translate>
    </span>
    <span className="navbar-version">{VERSION.toLowerCase().startsWith('v') ? VERSION : `v${VERSION}`}</span> */}
  </Link>
);

export const LinkItem = ({ to, icon, text = '', translate }) => (
  <Link to={to} className="nav-link-item">
    {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
    <span>
      <Translate contentKey={translate}>{text}</Translate>
    </span>
  </Link>
);
