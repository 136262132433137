import React, { useEffect, useMemo } from 'react';
import CalendarItem from './CalendarItem';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getCurrentUser } from 'app/shared/reducers/authentication.selector';
import dayjs from 'dayjs';
import { getResourceShifts, GetResourceShiftsByStartDateAndEndDateParams } from '../calendar.reducer';

const CalendarMobile = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(getCurrentUser);
  const startDate = dayjs().subtract(7, 'day').format('YYYY-MM-DD'); // today -7 days
  const endDate = dayjs().add(1, 'month').format('YYYY-MM-DD'); // today + 1 month

  const params = useMemo(
    () => ({
      resourceId: currentUser.id,
      startDate: new Date(`${startDate}T00:00:00.000Z`).toISOString(),
      endDate: new Date(`${endDate}T23:59:59.999Z`).toISOString(),
    }),
    [currentUser.id, startDate, endDate],
  );

  useEffect(() => {
    dispatch(getResourceShifts(params)).unwrap();
  }, [dispatch, params]);

  const generateCalendarItems = () => {
    const items = [];
    let currentDate = dayjs().subtract(7, 'day');

    while (currentDate.isBefore(dayjs().add(1, 'month'))) {
      items.push(<CalendarItem key={currentDate.format('YYYY-MM-DD')} date={currentDate} />);
      currentDate = currentDate.add(1, 'day');
    }

    return items;
  };

  return <div className="flex flex-col gap-1">{generateCalendarItems()}</div>;
};

export default CalendarMobile;
