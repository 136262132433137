// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link-item {

display: flex;

display: none;

align-items: center;

border-radius: calc(var(--radius) - 2px);

padding-left: 0.75rem;

padding-right: 0.75rem;

padding-top: 0.5rem;

padding-bottom: 0.5rem;

font-size: 0.875rem;

line-height: 1.25rem;

font-weight: 500;

--tw-text-opacity: 1;

color: rgb(209 213 219 / var(--tw-text-opacity))
}

.nav-link-item:hover {

--tw-bg-opacity: 1;

background-color: rgb(55 65 81 / var(--tw-bg-opacity));

--tw-text-opacity: 1;

color: rgb(255 255 255 / var(--tw-text-opacity))
}

.nav-link-item:focus {

--tw-bg-opacity: 1;

background-color: rgb(31 41 55 / var(--tw-bg-opacity))
}

@media (min-width: 640px) {
.nav-link-item {

display: block
}
}

.nav-link-item.active {

border-radius: calc(var(--radius) - 2px);

--tw-bg-opacity: 1;

background-color: rgb(17 24 39 / var(--tw-bg-opacity));

padding-left: 0.75rem;

padding-right: 0.75rem;

padding-top: 0.5rem;

padding-bottom: 0.5rem;

font-size: 0.875rem;

line-height: 1.25rem;

font-weight: 500;

--tw-text-opacity: 1;

color: rgb(255 255 255 / var(--tw-text-opacity))
}

.dropdown-item {

display: flex;

cursor: pointer;

align-items: center;

border-radius: calc(var(--radius) - 2px);

padding-left: 0.75rem;

padding-right: 0.75rem;

padding-top: 0.5rem;

padding-bottom: 0.5rem;

font-size: 0.875rem;

font-weight: 500;

line-height: 1.25rem;

--tw-text-opacity: 1;

color: rgb(31 41 55 / var(--tw-text-opacity))
}

.dropdown-item:hover {

--tw-bg-opacity: 1;

background-color: rgb(243 244 246 / var(--tw-bg-opacity));

--tw-text-opacity: 1;

color: rgb(17 24 39 / var(--tw-text-opacity))
}

.dropdown-item.active-side {

--tw-bg-opacity: 1;

background-color: rgb(219 234 254 / var(--tw-bg-opacity));

font-weight: 700;

--tw-text-opacity: 1;

color: rgb(37 99 235 / var(--tw-text-opacity))
}

.dropdown-item.active {

--tw-bg-opacity: 1;

background-color: rgb(107 114 128 / var(--tw-bg-opacity));

--tw-text-opacity: 1;

color: rgb(255 255 255 / var(--tw-text-opacity))
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/layout/header/header-components.css"],"names":[],"mappings":"AACE;;AAAA,aAAA;;AAAA,aAAA;;AAAA,mBAAA;;AAAA,wCAAA;;AAAA,qBAAA;;AAAA,sBAAA;;AAAA,mBAAA;;AAAA,sBAAA;;AAAA,mBAAA;;AAAA,oBAAA;;AAAA,gBAAA;;AAAA,oBAAA;;AAAA;AAAA;;AAAA;;AAAA,kBAAA;;AAAA,sDAAA;;AAAA,oBAAA;;AAAA;AAAA;;AAAA;;AAAA,kBAAA;;AAAA;AAAA;;AAAA;AAAA;;AAAA;AAAA;AAAA;;AAGA;;AAAA,wCAAA;;AAAA,kBAAA;;AAAA,sDAAA;;AAAA,qBAAA;;AAAA,sBAAA;;AAAA,mBAAA;;AAAA,sBAAA;;AAAA,mBAAA;;AAAA,oBAAA;;AAAA,gBAAA;;AAAA,oBAAA;;AAAA;AAAA;;AAGA;;AAAA,aAAA;;AAAA,eAAA;;AAAA,mBAAA;;AAAA,wCAAA;;AAAA,qBAAA;;AAAA,sBAAA;;AAAA,mBAAA;;AAAA,sBAAA;;AAAA,mBAAA;;AAAA,gBAAA;;AAAA,oBAAA;;AAAA,oBAAA;;AAAA;AAAA;;AAAA;;AAAA,kBAAA;;AAAA,yDAAA;;AAAA,oBAAA;;AAAA;AAAA;;AAIA;;AAAA,kBAAA;;AAAA,yDAAA;;AAAA,gBAAA;;AAAA,oBAAA;;AAAA;AAAA;;AAIA;;AAAA,kBAAA;;AAAA,yDAAA;;AAAA,oBAAA;;AAAA;AAAA","sourcesContent":[".nav-link-item {\n  @apply text-gray-300 focus:bg-gray-800 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium sm:block hidden flex items-center;\n}\n.nav-link-item.active {\n  @apply bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium;\n}\n.dropdown-item {\n  @apply px-3 py-2 cursor-pointer rounded-md text-sm font-medium flex items-center leading-5 text-gray-800 hover:bg-gray-100 hover:text-gray-900;\n}\n\n.dropdown-item.active-side {\n  @apply bg-blue-100 font-bold text-blue-600;\n}\n\n.dropdown-item.active {\n  @apply bg-gray-500 text-white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
