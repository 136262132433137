const colors = [
  'bg-red-200',
  'bg-red-300',
  'bg-red-400',
  'bg-red-500 text-white',
  'bg-red-600 text-white',
  'bg-blue-200',
  'bg-blue-300',
  'bg-blue-400',
  'bg-blue-500 text-white',
  'bg-blue-600 text-white',
  'bg-green-200',
  'bg-green-300',
  'bg-green-400',
  'bg-green-500 text-white',
  'bg-green-600 text-white',
  'bg-yellow-200',
  'bg-yellow-300',
  'bg-yellow-400',
  'bg-yellow-500 text-white',
  'bg-yellow-600 text-white',
  'bg-purple-200',
  'bg-purple-300',
  'bg-purple-400',
  'bg-purple-500 text-white',
  'bg-purple-600 text-white',
  'bg-pink-200',
  'bg-pink-300',
  'bg-pink-400',
  'bg-pink-500 text-white',
  'bg-pink-600 text-white',
  'bg-indigo-200',
  'bg-indigo-300',
  'bg-indigo-400',
  'bg-indigo-500 text-white',
  'bg-indigo-600 text-white',
  'bg-teal-200',
  'bg-teal-300',
  'bg-teal-400',
  'bg-teal-500 text-white',
  'bg-teal-600 text-white',
  'bg-orange-200',
  'bg-orange-300',
  'bg-orange-400',
  'bg-orange-500 text-white',
  'bg-orange-600 text-white',
];

export const getRandomColor = (): string => {
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

export const getBgColorById = (id: number) => {
  switch (id) {
    case 1:
      return 'bg-indigo-300'; // Light Indigo
    case 2:
      return 'bg-blue-200'; // Light Blue
    case 3:
      return 'bg-purple-200'; // Light Purple
    case 4:
      return 'bg-teal-200'; // Light Teal
    case 5:
      return 'bg-green-200'; // Light Green
    case 6:
      return 'bg-yellow-200'; // Light Yellow
    case 7:
      return 'bg-orange-200'; // Light Orange
    case 8:
      return 'bg-red-200'; // Light Red
    case 9:
      return 'bg-pink-200'; // Light Pink
    case 10:
      return 'bg-rose-200'; // Light Rose
    case 11:
      return 'bg-gray-200'; // Light Gray
    default:
      return ''; // Default
  }
};

export const getCalendarCellBgColorById = (id: number, returnRGB: boolean = false) => {
  if (returnRGB) {
    switch (id) {
      case 1:
      case 4:
      case 5:
        return 'rgb(190 242 100)';
      case 2:
      case 6:
      case 7:
        return 'rgb(252 211 77)';
      case 3:
      case 8:
      case 9:
        return 'rgb(103 232 249)';
      case 10:
        return 'rgb(252 165 165)';
      case 11:
        return 'rgb(209 213 219)';
      default:
        return '';
    }
  } else {
    switch (id) {
      case 1:
      case 4:
      case 5:
        return 'lime-300'; // Soft Lime
      case 2:
      case 6:
      case 7:
        return 'amber-300'; // Soft Amber
      case 3:
      case 8:
      case 9:
        return 'cyan-300'; // Soft Cyan
      case 10:
        return 'red-300'; // Soft Red
      case 11:
        return 'gray-300'; // Soft Gray
      default:
        return ''; // Default
    }
  }
};
export const getCalendarCellHoverBgColorById = (id: number) => {
  switch (id) {
    case 1:
      return 'bg-lime-400'; // Soft Lime
    case 2:
      return 'bg-amber-400'; // Soft Amber
    case 3:
      return 'bg-cyan-400'; // Soft Cyan
    case 4:
      return 'bg-lime-400'; // Soft Lime
    case 5:
      return 'bg-lime-400'; // Soft Cyan
    case 6:
      return 'bg-amber-400'; // Soft Amber
    case 7:
      return 'bg-amber-400'; // Soft Amber
    case 8:
      return 'bg-cyan-400'; // Soft Cyan
    case 9:
      return 'bg-cyan-400'; // Soft Cyan
    case 10:
      return 'bg-red-400'; // Soft Red
    case 11:
      return 'bg-gray-400'; // Soft Gray
    default:
      return ''; // Default
  }
};
